<template>
    <div>
        <div class="container-fluid grid-layout">
            <ParlamentarList 
                :is-painel-eletronico="false" 
                :exibir-apenas-apelido-vereador="exibirApenasApelidoVereador" 
            />

            <div class="presence d-none">
                <SpinnerDefault
                    v-if="loadingStore.data.telao.loadingVereadores"
                    class="text-dark"
                    :loading="loadingStore.data.telao.loadingVereadores"
                />
                <div v-if="!loadingStore.data.telao.loadingVereadores">
                    Vereadores Presentes: {{ presenceStore.data.presentes }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SessaoServiceTelao from '../domain/SessaoServiceTelao';
import PresenceStore from '../domain/storage/PresenceStore';
import LoadingStore from '../domain/storage/LoadingStore';
const ParlamentarList = () => import('../components/telao/ParlamentarList.vue');
const SpinnerDefault = () => import('../components/shared/SpinnerDefault.vue');

export default {
    components: {
        SpinnerDefault,
        ParlamentarList,
    },
    props: {
        telaoSettings: {
            type: Object,
            required: false
        },
        exibirApenasApelidoVereador: {
            type: Boolean,
            required: false
        }
    },
    data() {
        return {
            sessaoId: null,
            sessaoServiceTelao: new SessaoServiceTelao(this.$route.params.dominio),
            presenceStore: PresenceStore,
            loadingStore: LoadingStore
        };
    },

    created: function() {},

    mounted: function() {
        this.presenceStore.methods.clearPresentesAusentes();
    },

    beforeRouteLeave(to, from, next) {
        this.loadingStore.methods.setTelaoLoadingVereadores(true);
        next();
    }
};
</script>

<style scoped lang="scss">
.grid-layout {
    display: grid;
}

.presence {
    background-color: rgb(236, 236, 236);
    color: #000;
    padding: 20px;
    font-size: 1.5rem;
    align-self: end;
    display: grid;
    margin-top: 40px;
    place-items: center;

    @media (min-width: 1200px) {
        font-size: 2.2em;
    }

    @media (min-width: 2000px) {
        font-size: 2.8em;
    }
}
</style>

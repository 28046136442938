const LoadingStore = {
    data: {
        telao: {
            loadingVereadores: true
        }
    },

    methods: {
        setTelaoLoadingVereadores(newVal) {
            LoadingStore.data.telao.loadingVereadores = newVal;
        }
    }
};

export default LoadingStore;

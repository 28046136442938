const PresenceStore = {
    data: {
        debug: process.env.VUE_APP_PORTYX_DEBUG,
        presentes: 0,
        ausentes: 0
    },

    methods: {
        addPresent() {
            PresenceStore.data.presentes += 1;
        },

        addAbsent() {
            PresenceStore.data.ausentes += 1;
        },

        subAbsent() {
            PresenceStore.data.ausentes -= 1;
        },

        clearPresentesAusentes() {
            PresenceStore.data.presentes = 0;
            PresenceStore.data.ausentes = 0;
        }
    }
};

export default PresenceStore;
